<template>
    <div class="card">
        <div class="bg-blue-light p-1 mb-2">
            <TitleButton 
                :showBtn="true"
                :showAddNew="false"
                :title="`Pending Samples (${replaceDashWithSpace($route.meta.slug, 'capitalized')})`"
            />
        </div>

        <div class="my-2 px-2">
            <DateQuerySetter
                btnTitle="Go"
                @onClickGo="onClickGo"
            >
                <div class="col-12 col-sm-6 col-md-4 col-lg-3">
                    <label for="colFormLabel" class="col-form-label">Patient: </label>
                    <AsyncSelect
                      placeholder="Patient Name, ID, Mobile No"
                      v-model="patient"
                      :api-service="fetchContactProfiles"
                      :additional-query="{ type: 'patient'}"
                      :format-label="formatPatientLabel"
                    />
                </div>
            </DateQuerySetter>
        </div>

        <div class="col-12 px-2 mt-2">

            <ListTable 
                :investigations="investigations"
            />

            <div class="px-2 bottom-0 mt-2">
                <Pagination
                    :offset="offset"
                    @onPageChange="onPageChange"
                    ref="pagination"
                />
            </div>

        </div>

        <Loader v-if="loading"/>
    </div>
</template>

<script setup>
    import TitleButton from '@/components/atom/TitleButton.vue';
    import DateQuerySetter from '@/components/atom/DateQuerySetter.vue';
    import ListTable from '@/components/molecule/company/hospital/investigation-general/InvestigationBillingTable.vue';
    import Pagination from '@/components/atom/Pagination'
    import {useRoute, useRouter} from "vue-router";
    import handlePurchase from "@/services/modules/purchase";
    import handleContact from "@/services/modules/contact";
    import {computed, inject, onMounted, ref} from "vue";
    import Loader from "@/components/atom/LoaderComponent.vue";
    import {useStore} from "vuex";
    import { replaceDashWithUnderscore, replaceDashWithSpace } from '@/services/utils/global';
    import AsyncSelect from "@/components/molecule/input-field/AsyncSelect.vue";
    import {useAsyncDropdownHelper} from "@/services/utils/asyncDropdownHelper";

    const $route = useRoute();
    const $router = useRouter();
    const $store = useStore();

    const showError = inject('showError');
    const showSuccess = inject('showSuccess');
    const { fetchSalesList } = handlePurchase();
    const { fetchContactProfiles } = handleContact();
    const { formatPatientLabel } = useAsyncDropdownHelper()

    const offset = ref(200);
    const loading = ref(false);
    const investigations = ref([])
    const status = ref(null)
    const patient = ref(null)

    const start = computed(() => $route.query.start);
    const end = computed(() => $route.query.end);
    const page = computed(() => $route.query.page);
    const companyId = computed(() => $route.params.companyId);

    const setPaginationData = (data) => {
        $store.commit('setPaginationData', data)
    };

    const onPageChange = async (page) => {
        let routeQuery = Object.assign({}, $route.query)
        routeQuery.page = page
        await $router.push({path: $route.path, query: routeQuery})
        await getSalesList()
    }

    const setPagination = (data) => {
        setPaginationData({
          records: data.total,
          perPage: data.per_page,
          currentPage: data.current_page,
        })
    }

    const onClickGo = async () => {
        await getSalesList();
    }

    const getQuery = () => {
        let query = '?company_id=' + companyId.value
        if (!start && !end) return query
        if(!!patient.value) query += '&contact_profile_id=' + patient.value.id
        if (start) query += '&start_date=' + start.value
        if (end) query += '&end_date=' + end.value
        if (page) query += '&page=' + page.value
        if (status.value) query += '&status=' + status.value
        query += '&offset=' + offset.value
        query += '&sale_type=investigation_invoice'
        query += '&investigation_type=' + replaceDashWithUnderscore($route.meta.slug)
        query += '&custom_order=1';
        query += '&only_accepted=1';
        return query
    };

    async function getSalesList() {
      loading.value = true;
      try {
        const res = await fetchSalesList(getQuery());
        if (!res.status) {
          investigations.value = []
          return $store.commit("resetPagination")
        }
        investigations.value = res.date.data;
        if (investigations.value.length > 0) {
          setPagination(res.date)
        }
      } catch (err) {
        if (err.response) {
          showError(err.response.message)
        }
      } finally {
        loading.value = false;
      }
    }

    onMounted( async () => {
        await Promise.all([
          getSalesList()
        ])
    })
</script>