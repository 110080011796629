<template>
    <div class="card investigation-card">
        <div class="table-responsive">
            <table class="table custom-table-padding">
                <thead>
                    <tr>
                        <th>Bill no</th>
                        <th>Date</th>
                        <th class="patient-detail-th">Patient Details</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(item, i) in investigations" :key="i">
                        <td>{{ item.bill_number }}</td>
                        <td>{{ item.date }}</td>
                        <td> 
                            <div class="col">
                                <p><strong>{{ item.party_name }}</strong></p>
                                <p>{{ getPartyInfo(item.contact, 'mobile_no') }}</p>
                            </div>
                        </td>
                        <td>
                            <button @click="onClickToNext(item)" class="btn btn-primary btn-sm">
                                <i class="fas fa-arrow-right"></i>
                                Next
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>

            <p v-if="!investigations.length" class="text-center mt-5">No data</p>
        </div>
    </div>
</template>

<script setup>
    import figureFormatter from "@/services/utils/figureFormatter";
    import handleBarcode from "@/services/modules/barcode";
    import {useRoute, useRouter} from "vue-router";
    import handleHospital from "@/services/modules/hospital";
    import investigationResultPdfPrinter from '@/services/utils/investigationResultPdfPrinter';
    import { inject, onMounted, ref } from "vue";
    import handleCompany from "@/services/modules/company";
    import { useStore } from "vuex";
    import { formatDate } from '@/services/utils/global';

    const props = defineProps({
        investigations: Array,
    })

    const $emit = defineEmits(['onEdit']);
    const $route = useRoute();
    const $router = useRouter();
    const $store = useStore();
    const showError = inject('showError');
    const showSuccess = inject('showSuccess');

    const onClickToNext = (investigationBill) => {
        const params = Object.assign({}, $route.params);
        params.billId = investigationBill.id;
        params.type = $route.meta.slug;

        $router.push({
          name: 'pending-sample-reports',
          params: params,
          query: $route.query,
        })
    };

    const getPartyInfo = (contact, fieldName) => {
        if(!(contact && contact.profile_items)) return "";
        const field = contact.profile_items.find(item => item.field === fieldName)
        return field && field.value
    };

</script>

<style scoped>
.investigation-card{
    min-height: 200px
}
.image-container{
    width: 20px;
}
.avatar-image{
    width: 65px;
}
.patient-detail-th{
    width: 30%;
}
</style>